import React from 'react';
import styled from 'styled-components';

export const RowContainer = styled.div`
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  margin-bottom: 20px;

  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  flex: ${({ space }) => space};
  min-width: calc(100% / 3 - 16px);
  
  @media (max-width: 400px) {
    min-width: 100%;
  }
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-weight: bolder;
  font-size: 12px;
  color: #787777;
`;