import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border: ${props => props.secondaryColor || '4px solid #ccc'};  
  border-left-color: ${props => props.primaryColor || '#0090A7'};
  border-radius: 50%;
  width: ${props => props.size || '10px'};
  height: ${props => props.size || '10px'};
  animation: ${spin} 0.4s linear infinite;
`;