import React from 'react';
import {
    RowContainer,
    InputContainer,
    Label
} from './Style';

export default function Row ({ inputs }) {
    return (
      <RowContainer>
        {inputs.map((input, index) => (
          <InputContainer key={index} space={input.espacoOcupado}>
            <Label>{input.label}</Label>
            {input.input}
          </InputContainer>
        ))}
      </RowContainer>
    );
  };