import React from 'react';
import {
    Container,
    InfoRow,
    Label,
    Value,
    Message,
    ErrorMessage,
    CityState,
} from './Styles'

export default function DeliveryInfo({ deliveryData, cepEnabled }) {
    function swapCommaDot(number) {
        let str = number.toString();
        let swappedStr = str
          .replace(/\./g, '#TEMP#')
          .replace(/,/g, '.')
          .replace(/#TEMP#/g, ',');
        return swappedStr;
      }

    const fullAddress = (
        <>
          {deliveryData.address} nº {deliveryData.number}, {''}
          <CityState>
            {deliveryData.city} - {deliveryData.uf}
          </CityState>
        </>
      );

  return (
    <Container>
      {cepEnabled ? (
        <>
            <InfoRow>
                <Value>{fullAddress}</Value>
            </InfoRow>
            <InfoRow>
                <Label>CEP:</Label>
                <Value>{deliveryData.cep}</Value>
            </InfoRow>
            <InfoRow>
                <Label>Quantidade:</Label>
                <Value>{deliveryData.amount}</Value>
            </InfoRow>
            <InfoRow>
                <Label>Valor do Frete:</Label>
                <Value>R${deliveryData.deliveryValue ? swapCommaDot(deliveryData.deliveryValue.toFixed(2)) : ''}</Value>
            </InfoRow>
        </>
      ) : (
        <></>
      )}
    </Container>
  );
}