import React, { useState } from 'react';
import {
    QuantityContainer,
    QuantityButton,
    QuantityInput
} from './Style';
import StyledInput from './../StyledInput/StyledInput';

export default function AmountInput ({ amount, setAmount, required }) {

    const handleAmountChange = (e) => {
        setAmount(Math.max(1, Number(e.target.value)));
    };

    const incrementAmount = () => {
        setAmount((prevAmount) => prevAmount + 1);
    };

    const decrementAmount = () => {
        setAmount((prevAmount) => Math.max(1, prevAmount - 1));
    };
  
    //const total = (price * amount).toFixed(2);
  
    return (
        <QuantityContainer>
            <QuantityButton onClick={decrementAmount} disabled={amount <= 1}>
                -
            </QuantityButton>
            <QuantityInput 
                type="number" 
                value={amount} 
                onChange={handleAmountChange}
                min="1"
                required={required}
            />
            <QuantityButton onClick={incrementAmount}>
                +
            </QuantityButton>
        </QuantityContainer>
    );
  };