import { React, useState} from 'react';
import StyledInput  from './../StyledInput/StyledInput';
import { validateCep } from './../../utils/Validation';
import { getCepData, getDeliveryData } from './../../services/Service';
import Swal from 'sweetalert2';


const CepInput = ({ cep, setCep, setNeighborhood, setAddress, setCity, setUf, loading, setLoading, setCepEnabled, setDeliveryMode, setDeliveryValue, setDeliveryAmount}) => {
    const [error, setError] = useState(false);
    const maxLength = 9;

    function validate(cep) {
        const validation = validateCep(cep);
        if (validation){
            setError(false);
        } else if (cep === '') {
            setError("Este campo é obrigatório");
        } else {
            setError("CEP inválido");
        }
    }

    const handleBlur = (cep) => {
        validate(cep);
    };

    const handleChange = async (cep) => {
        const newCep = cep.replace(/\D/g, '');
        const maskedCep = newCep.replace(/^(\d{5})(\d)/, '$1-$2');
        setCep(maskedCep);
        setError(false);
        if (newCep.length === 8) {
            setLoading(true);
            const result = await getCepData(cep);
            if (result.isSuccess){
                setError('');
                const deliveryDataResult = await getDeliveryData(cep);
                if (deliveryDataResult.isSuccess){
                    setError('');
                    setCepEnabled(true);
                    setNeighborhood(result.data.bairro);
                    setAddress(result.data.logradouro);
                    setCity(result.data.localidade);
                    setUf(result.data.uf);
                    setDeliveryAmount(deliveryDataResult.data.quantidadeDeEntrega);
                    setDeliveryValue(deliveryDataResult.data.frete);
                    setDeliveryMode(deliveryDataResult.data.modoDeEntrega);
                } else {
                    Swal.fire({
                        text: 'Entrega não disponível para esse local',
                        icon: 'error',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#0090A7'
                      });
                    setError('');
                    setCepEnabled(false);
                    setNeighborhood('');
                    setAddress('');
                    setCity('');
                    setUf('');
                }
            } else {
                setError("Insira um CEP válido");
                setCepEnabled(false);
                setNeighborhood('');
                setAddress('');
                setCity('');
                setUf('');
            }
            
            setLoading(false);
        }
    };
  
    return (
      <div>
        <StyledInput type='text' value={cep} onChange={handleChange} onBlur={handleBlur} errorMessage={error} loading={loading} maxLength={maxLength}/>
      </div>
    );
  };

export default CepInput;