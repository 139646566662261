export function validatePhone(phone) {
    const regex = /^\(?\d{2}\)?[\s-]?9\d{4}-?\d{4}$/;
    return regex.test(phone);
}

export function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
}

export function validateCep(cep) {
    const regex = /^[0-9]{5}-?[0-9]{3}$/;
    if (regex.test(cep)) {
        return true;
    } else {
        return false;
    }
} 


  
export const validateUF = (uf) => {
    const validUFs = ['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MG', 'MS', 'MT', 'PA', 'PB', 'PE', 'PI', 'PR', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO'];
    return validUFs.includes(uf.toUpperCase());
};

export function validateCpf(cpf) {
    const regex = /^\d{3}\.?\d{3}\.?\d{3}-?\d{2}$/;

    if (!regex.test(cpf)) {
        return false;
    }

    cpf = cpf.replace(/[^\d]+/g, '');

    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
        return false;
    }

    let sum = 0;
    for (let i = 0; i < 9; i++) {
        sum += parseInt(cpf.charAt(i)) * (10 - i);
    }

    let checkDigit = 11 - (sum % 11);
    if (checkDigit === 10 || checkDigit === 11) {
        checkDigit = 0;
    }

    if (checkDigit !== parseInt(cpf.charAt(9))) {
        return false;
    }

    sum = 0;
    for (let i = 0; i < 10; i++) {
        sum += parseInt(cpf.charAt(i)) * (11 - i);
    }

    checkDigit = 11 - (sum % 11);
    if (checkDigit === 10 || checkDigit === 11) {
        checkDigit = 0;
    }

    return checkDigit === parseInt(cpf.charAt(10));
}

export function validateCnpj(cnpj) {
    const regex = /^\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}$/;

    if (!regex.test(cnpj)) {
        return false;
    }

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj.length !== 14 || /^(\d)\1{13}$/.test(cnpj)) {
        return false;
    }

    let length = cnpj.length - 2;
    let numbers = cnpj.substring(0, length);
    let digits = cnpj.substring(length);
    let sum = 0;
    let pos = length - 7;

    for (let i = length; i >= 1; i--) {
        sum += numbers.charAt(length - i) * pos--;
        if (pos < 2) pos = 9;
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    if (result !== parseInt(digits.charAt(0))) {
        return false;
    }

    length++;
    numbers = cnpj.substring(0, length);
    sum = 0;
    pos = length - 7;

    for (let i = length; i >= 1; i--) {
        sum += numbers.charAt(length - i) * pos--;
        if (pos < 2) pos = 9;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
    return result === parseInt(digits.charAt(1));
}