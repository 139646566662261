import { React, useState} from 'react';
import StyledInput  from './../StyledInput/StyledInput';
import { validateEmail } from './../../utils/Validation';

export default function EmailInput ({ email, setEmail }) {
    const [error, setError] = useState(false);
    const maxLength = 77;

    function validate(email) {
        const validation = validateEmail(email);
        if (validation){
            setError(false);
        } else if (email === '') {
            setError("Este campo é obrigatório");
        } else {
            setError("Email inválido");
        }
    }

    const handleBlur = (email) => {
        validate(email);
    };

    const handleChange = async (email) => {
        setEmail(email);
        setError(false);
    };
  
    return (
      <div>
        <StyledInput type='text' value={email} onChange={handleChange} onBlur={handleBlur} errorMessage={error} loading={false} maxLength={maxLength}/>
      </div>
    );
  };