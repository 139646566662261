import React from 'react';
import { 
  InputContainer,
  Input,
  LoadingIcon,
  ErrorMessage,
} from './Styles';
import Loader from '../Loader/Loader';

export default function StyledInput({ type, placeholder, value, onChange, onBlur = false, errorMessage = false, loading = false, maxLength = false, width = '100%', green }) {
  const handleChange = (e) => {
    let value = e.target.value;

    if (maxLength && value.length > maxLength) {
      value = value.slice(0, maxLength);
    }
    
    onChange(value);
  };

  const handleBlur = (e) => {
    onBlur && onBlur(e.target.value);
  };

  return (
    <InputContainer>
      {errorMessage && !loading ? (errorMessage === "Este campo é obrigatório" && value !== '' ? <></> : <ErrorMessage>{errorMessage}</ErrorMessage>) : <></>}
      <Input 
        type={type} 
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{width: width}}
        green={green}
      />
      {loading && <LoadingIcon><Loader/></LoadingIcon>}
    </InputContainer>
  );
};