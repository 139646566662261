import { React, useState} from 'react';
import StyledInput  from './../StyledInput/StyledInput';

export default function TextInput ({ value, setValue, placeholder, type = 'text', required = false, loading = false, maxLength = false, minLength = false, width = '100%', green }) {
    const [error, setError] = useState(false);

    function validate(value) {
        if (value === '' && required) {
            setError("Este campo é obrigatório");
        } else if (value.length < minLength){
            setError("Este campo está inválido");
        }
    }

    const handleBlur = (value) => {
        validate(value);
    };

    const handleChange = async (value) => {
        setValue(value);
        setError(false);
    };
  
    return (
      <div>
        <StyledInput type={type} placeholder={placeholder} value={value} onChange={handleChange} onBlur={handleBlur} errorMessage={error} loading={loading} maxLength={maxLength} width={width} green={green}/>
      </div>
    );
  };