import React from 'react';
import {
    TotalValue,
    TotalContainer,
    TotalLabel,
    TotalText,
} from './Styles';

export default function TotalRow ({ value }) {
    function swapCommaDot(number) {
        let str = number.toString();
        let swappedStr = str
          .replace(/\./g, '#TEMP#')
          .replace(/,/g, '.')
          .replace(/#TEMP#/g, ',');
        return swappedStr;
      }

    return (
      <TotalContainer>
        <TotalText>
          <TotalLabel>Total</TotalLabel>
          <TotalValue>R${swapCommaDot(value.toFixed(2))}</TotalValue>
        </TotalText>
      </TotalContainer>
    );
  };