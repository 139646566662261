import React from 'react';
import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 20px;
  width: 100%;
  height: 90px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  background-color: #0090A7;
  margin-bottom: 20px;
`;

export const Logo = styled.img`
  width: 180px;
  height: 180px;
`;

export const TextDiv = styled.div`
  display: block;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 220px;
`;

export const Text = styled.div`
  font-size: 12px;
  color: white;
`;

export const Title = styled.div`
  font-weight: bolder;
  font-size: 16px;
  color: white;
`;