import React from 'react';
import styled from 'styled-components';

export const QuantityContainer = styled.div`
  padding-right: 20px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const QuantityButton = styled.button`
  width: 100%;
  max-width: 28px;
  height: 28px;
  font-size: 1.2em;
  background-color: #0090A7;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1px;
  border-left: 1px #ddd solid;
  border-bottom: 2px #ddd solid;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const QuantityInput = styled.input`
  max-width: 60px;
  height: 32px;
  font-size: 1em;
  border: none;
  border-radius: 8px;
  border-left: 1px #ddd solid;
  border-bottom: 4px #ddd solid;
  text-align: center;
  &:focus {
    border-color: #0090A7;
    outline: none;
  }
  &::-webkit-inner-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
    }
  &::-webkit-outer-spin-button{
    -webkit-appearance: none; 
    margin: 0; 
  }  
`;