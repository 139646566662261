import APIResponse from './../utils/APIResponse';

export const getCepData = async (cep) => {
    const maxRetries = 3;
    let attempts = 0;
    let lastError = null;
  
    while (attempts < maxRetries) {
      try {
        const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
        if (!response.ok) {
          throw new Error('Falha ao buscar os dados');
        }
        const data = await response.json();
        if (data.erro) {
          throw new Error('CEP não encontrado');
        }
        return APIResponse.success(data);
      } catch (error) {
        lastError = error;
        attempts += 1;

        if (attempts >= maxRetries) {
          return APIResponse.error(error.message);
        }
      }
    }
}

export const postData = async (data = {}) => {
  try {
    //const baseUrl = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://localhost:7069';
    //const url = `${baseUrl}/Assinaturas/app-criar-assinatura`;
    const url = `https://petutti-assinatura-api.azurewebsites.net/api/Assinaturas/app-criar-assinatura`;
    const request = {
      Logradouro: data.address,
      Quantidade: data.amount,
      Cep: data.cep,
      Cidade: data.city,
      Complemento: data.complement,
      coupon: data.coupon,
      CPF: data.document,
      email: data.email,
      NomeCompleto: data.name,
      Bairro: data.neighborhood,
      Numero: data.number,
      Telefone: data.phone,
      Estado: data.uf,
      cupom: data.coupon
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });

    if (response.ok) {
      const result = JSON.parse(await response.text());
      if (result.isSuccess){
        return APIResponse.success(result.data);
      } else {
        return APIResponse.error(result.message);
      }
    } else {
      const result = JSON.parse(await response.text());
      return APIResponse.error(result.message);
    }
  } catch (error) {
    return APIResponse.error(error.message);
  }
};

export const checkCoupon = async (data = {}) => {
  try {
    //const baseUrl = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://localhost:7069';
    //const url = `${baseUrl}/Cupons/nome?name=${data.coupon}`;
    const url = `https://petutti-assinatura-api.azurewebsites.net/api/Cupons/nome?name=${data.coupon}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const result = JSON.parse(await response.text());
      return APIResponse.success({
        porcentagem: result.porcentagem
      });
    } else {
      return APIResponse.error({
        message: 'Cupom não encontrado'
      });
    }
  } catch (error) {
    return APIResponse.error(error.message);
  }
};

export const getDeliveryData = async (cep) => {
  try {
    //const baseUrl = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : 'https://localhost:7069';
    //const url = `${baseUrl}/Cupons/nome?name=${data.coupon}`;
    const url = `https://petutti-assinatura-api.azurewebsites.net/api/Fretes/cep/${cep}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const result = JSON.parse(await response.text());
      return APIResponse.success({
        frete: result.valor,
        modoDeEntrega: result.valor,
        quantidadeDeEntrega: result.quantidadeMaxima
      });
    } else {
      return APIResponse.error({
        message: 'Entrega não disponível para esse local'
      });
    }
  } catch (error) {
    return APIResponse.error(error.message);
  }
};