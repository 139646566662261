import { React, useState} from 'react';
import StyledInput  from './../StyledInput/StyledInput';
import { validateCpf } from './../../utils/Validation';
import { validateCnpj } from './../../utils/Validation';

export default function DocumentInput ({ document, setDocument }) {
    const [error, setError] = useState(false);
    const maxLength = 14;

    function validate(document) {
        if (document.length == 14){
            const cpfValidation = validateCpf(document);
            if (cpfValidation){
                setError(false);
            } else {
                setError("CPF inválido");
            }
        /*} else if (document.length == 18) {
            const cnpjValidation = validateCnpj(document);
            if (cnpjValidation){
                setError(false);
            } else {
                setError("CNPJ inválido");
            }*/
        } else {
            if (document == ''){
                setError("Insira o CPF");
            } else {
                setError("CPF inválido");
            }
        }
    }

    const handleBlur = (document) => {
        validate(document);
    };

    const handleChange = async (document) => {
        const cleanedDocument = document.replace(/\D/g, '');
        let maskedDocument;
    
        if (cleanedDocument.length <= 11) {
            maskedDocument = cleanedDocument
                .replace(/^(\d{3})(\d)/, '$1.$2')
                .replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3')
                .replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d{1,2})/, '$1.$2.$3-$4');
        }/* else if (cleanedDocument.length <= 14) {
            maskedDocument = cleanedDocument
                .replace(/^(\d{2})(\d)/, '$1.$2')
                .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
                .replace(/^(\d{2})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3/$4')
                .replace(/^(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})(\d{1,2})/, '$1.$2.$3/$4-$5');
        }*/
    
        setDocument(maskedDocument);
        setError(false);
    };
  
    return (
      <div>
        <StyledInput type='text' value={document} onChange={handleChange} onBlur={handleBlur} errorMessage={error} loading={false} maxLength={maxLength}/>
      </div>
    );
  };