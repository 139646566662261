import { React, useState, useEffect } from 'react';
import './App.css';
import { checkCoupon, postData } from './services/Service';
import Header from './components/Header/Header';
import CepInput from './components/CepInput/CepInput';
import PhoneInput from './components/PhoneInput/PhoneInput';
import EmailInput from './components/EmailInput/EmailInput';
import TextInput from './components/TextInput/TextInput';
import UfInput from './components/UfInput/UfInput';
import DocumentInput from './components/DocumentInput/DocumentInput';
import AmountInput from './components/AmountInput/AmountInput';
import Button from './components/Button/Button';
import Divider from './components/Divider/Divider';
import ProductsRow from './components/ProductsRow/ProductsRow';
import TotalRow from './components/TotalRow/TotalRow';
import Row from './components/Row/Row';
import Swal from 'sweetalert2';
import Loader from './components/Loader/Loader';
import DeliveryInfo from './components/DeliveryInfo/DeliveryInfo';
import { validateCep, validateCpf, validateEmail, validatePhone, validateUF } from './utils/Validation';

function App() {
  const [loadingCep, setLoadingCep] = useState(false);
  const [loadingApi, setLoadingApi] = useState(false);
  const [loadingCoupon, setLoadingCoupon] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [document, setDocument] = useState('');
  const [cep, setCep] = useState('');
  const [neighborhood, setNeighborhood] = useState('');
  const [address, setAddress] = useState('');
  const [number, setNumber] = useState('');
  const [city, setCity] = useState('');
  const [uf, setUf] = useState('');
  const [complement, setComplement] = useState('');
  const [amount, setAmount] = useState(1);
  const [coupon, setCoupon] = useState('');
  const [discount, setDiscount] = useState(10);
  const product = 'Tapete Higiênico Petutti (30un)';
  
  const [cepEnabled, setCepEnabled] = useState(false);
  const [deliveryMode, setDeliveryMode] = useState('');
  const [deliveryValue, setDeliveryValue] = useState(0);
  const [deliveryAmount, setDeliveryAmount] = useState(0);
  const [deliveryTotalValue, setDeliveryTotalValue] = useState(0);
  const price = 97.45;
  const discountedValue = price - price * discount / 100;
  const [total, setTotal] = useState(discount !== 10 ? (price + deliveryTotalValue - (price * discount / 100)) : (price + deliveryTotalValue));

  useEffect(() => {
    setTotal((discountedValue  * amount) + deliveryTotalValue);
  }, [amount, discount])

  useEffect(() => {
    console.log('useEffect')
    if (deliveryAmount > 0 && deliveryValue > 0) {
      const multiplier = Math.ceil(amount / deliveryAmount);
      setDeliveryTotalValue(multiplier * deliveryValue);
      setTotal((discountedValue  * amount) + multiplier * deliveryValue);
    } else {
      setDeliveryTotalValue(0);
    }
  }, [amount, deliveryValue, deliveryAmount]);

  useEffect(() => {
    if (!validateCep(cep)) {
      setDeliveryValue(0);
      setDeliveryAmount(0);
      setDeliveryTotalValue(0);
      setDeliveryMode('');
      setCepEnabled(false);
    } else {
      setCepEnabled(true);
    }
  }, [cep]);

  async function handleClick() {
    if (!validateCep(cep)) {
      Swal.fire({
        text: 'Insira um CEP válido',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#0090A7'
      });
      return;
    } else if (!validateCpf(document)) {
      Swal.fire({
        text: 'Insira um CPF válido',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#0090A7'
      });
      return;
    } else if (!validateEmail(email)) {
      Swal.fire({
        text: 'Insira um e-mail válido',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#0090A7'
      });
      return;
    } else if (!validatePhone(phone)) {
      Swal.fire({
        text: 'Insira um celular válido',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#0090A7'
      });
      return;
    } else if (!validateUF(uf)) {
      Swal.fire({
        text: 'Insira um UF válido',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#0090A7'
      });
      return;
    } else if (name === '') {
      Swal.fire({
        text: 'Insira o nome',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#0090A7'
      });
      return;
    } else if (neighborhood === '') {
      Swal.fire({
        text: 'Insira o bairro',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#0090A7'
      });
      return;
    } else if (address === '') {
      Swal.fire({
        text: 'Insira o logradouro',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#0090A7'
      });
      return;
    } else if (number === '') {
      Swal.fire({
        text: 'Insira o número',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#0090A7'
      });
      return;
    } else if (city === '') {
      Swal.fire({
        text: 'Insira a cidade',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#0090A7'
      });
      return;
    }

    setLoadingApi(true);

    const data = {
      name: name,
      email: email,
      phone: phone,
      document: document,
      cep: cep,
      neighborhood: neighborhood,
      address: address,
      number: number,
      city: city,
      uf: uf,
      complement: complement,
      product: product,
      amount: amount,
      coupon: coupon
    };

    const response = await postData(data);
    setLoadingApi(false);

    if (response.isSuccess){
      Swal.fire({
        html: '<strong>Assinatura criada com sucesso!</strong><br>Enviamos um e-mail para você concluir o pagamento da sua assinatura, confira a sua caixa de entrada.',
        icon: 'success',
        confirmButtonText: 'OK',
        confirmButtonColor: '#0090A7'
      });
      setName('');
      setEmail('');
      setPhone('');
      setDocument('');
      setCep('');
      setNeighborhood('');
      setAddress('');
      setNumber('');
      setCity('');
      setUf('');
      setComplement('');
      setCoupon('');
      setAmount(1);
    } else {
      Swal.fire({
        text: response.message ? response.message : 'Ocorreu um erro interno. Tente novamente mais tarde',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#0090A7'
      });
    }
  }
  
  const handleChangeCoupon = (value) => {
    setCoupon(value);
    setDiscount(10);
  }

  async function validateCoupon() {
    setLoadingCoupon(true);
    
    const data = {
      coupon: coupon
    };

    const response = await checkCoupon(data);
    setLoadingCoupon(false);

    if (response.isSuccess){
      setDiscount(response.data.porcentagem)
    } else {
      setDiscount(10)
      Swal.fire({
        text: 'Cupom não encontrado',
        icon: 'error',
        confirmButtonText: 'OK',
        confirmButtonColor: '#0090A7'
      });
    }
  }

  function buttonAvaliable(){
    if (!validateCep(cep) ||
        !validateEmail(email) ||
        !validatePhone(phone) ||
        !validateCpf(document) ||
        !validateUF(uf) ||
        name === '' ||
        neighborhood === '' ||
        address === '' ||
        number === '' ||
        city === '') {
        return false;
      } else {
        return true;
      }
  }

  return (
    <div className='page'>
      <Header/>
      <div className='content'>
          <div className='form-container'>
            <div className='inputs-container'>
              <Divider
                text={'Produto'}
              />
              <ProductsRow 
                products={[
                  {
                    name: product,
                    value: price,
                    discountedValue: discountedValue
                  }
                ]}
              />
              <Row 
                inputs={
                  [
                    {
                      espacoOcupado: 1,
                      label: 'Quantidade',
                      input: (
                        <AmountInput
                          price={discountedValue}
                          amount={amount}
                          setAmount={setAmount}
                          required={true}
                        />
                      )
                    },
                    {
                      espacoOcupado: 1,
                      label: 'Cupom',
                      input: (
                        <TextInput
                          value={coupon}
                          setValue={handleChangeCoupon}
                          green={discount!==10}
                        />
                      )
                    }
                  ]
                }
              />
              <div style={{width:'90%', display:'flex', flexDirection:'row', justifyContent:'end'}}>
                <Button onClick={validateCoupon} disabled={loadingCoupon}>
                  {loadingCoupon ? (
                    <Loader 
                      size={'20px'}
                      primaryColor={'white'}
                      secondaryColor={'4px solid transparent'}
                    />
                  ) : (
                    'Aplicar'
                  )}
                </Button>
              </div>
              
              <TotalRow 
                value={total}
              />
              <Divider
                number={'1'}
                text={'Dados Pessoais'}
                divider={true}
              />
              <Row 
                inputs={
                  [
                    {
                      espacoOcupado: 3,
                      label: 'Nome Completo *',
                      input: (
                        <div className='input-container'>
                          <TextInput
                            value={name}
                            setValue={setName}
                            required={true}
                          />
                        </div>
                      )
                    },
                  ]
                }
              />
              <Row 
                inputs={
                  [
                    {
                      espacoOcupado: 1.5,
                      label: 'CPF *',
                      input: (
                        <div className='input-container'>
                          <DocumentInput 
                            document={document} 
                            setDocument={setDocument}
                          />
                        </div>
                      )
                    },
                    {
                      espacoOcupado: 1.5,
                      label: 'Celular *',
                      input: (
                        <div className='input-container'>
                          <PhoneInput 
                            phone={phone} 
                            setPhone={setPhone}
                          />
                        </div>
                      )
                    },
                  ]
                }
              />
              <Row 
                inputs={
                  [
                    {
                      espacoOcupado: 3,
                      label: 'Email *',
                      input: (
                        <div className='input-container'>
                        <EmailInput 
                          email={email} 
                          setEmail={setEmail}
                        />
                        </div>
                      )
                    }
                  ]
                }
              />
              <Divider
                number={'2'}
                text={'Dados da Entrega'}
                divider={true}
              />
              <Row 
                inputs={
                  [
                    {
                      espacoOcupado: 1,
                      label: 'CEP *',
                      input: (
                        <div className='input-container'>
                          <CepInput
                            cep={cep}
                            setCep={setCep} 
                            setNeighborhood={setNeighborhood}
                            setAddress={setAddress}
                            setNumber={setNumber}
                            setCity={setCity}
                            setUf={setUf}
                            loading={loadingCep}
                            setLoading={setLoadingCep}
                            setCepEnabled={setCepEnabled}
                            setDeliveryAmount={setDeliveryAmount}
                            setDeliveryValue={setDeliveryValue}
                            setDeliveryMode={setDeliveryMode}
                          />
                        </div>
                      )
                    },
                    {
                      espacoOcupado: 2,
                      label: '',
                      input: (<></>)
                    }
                  ]
                }
              />
              <Row 
                inputs={
                  [
                    {
                      espacoOcupado: 1.5,
                      label: 'Endereço *',
                      input: (
                        <div className='input-container'>
                          <TextInput
                            value={address}
                            setValue={setAddress}
                            loading={loadingCep}
                            required={true}
                          />
                        </div>
                      )
                    },
                    {
                      espacoOcupado: 1.5,
                      label: 'Bairro *',
                      input: (
                        <div className='input-container'>
                          <TextInput
                            value={neighborhood}
                            setValue={setNeighborhood}
                            loading={loadingCep}
                            required={true}
                          />
                        </div>
                      )
                    }
                  ]
                }
              />
              <Row 
                inputs={
                  [
                    {
                      espacoOcupado: 2,
                      label: 'Cidade *',
                      input: (
                        <div className='input-container'>
                          <TextInput
                            value={city}
                            setValue={setCity}
                            loading={loadingCep}
                            required={true}
                          />
                        </div>
                      )
                    },
                    {
                      espacoOcupado: 1,
                      label: 'UF *',
                      input: (
                        <div className='input-container'>
                          <UfInput
                            uf={uf}
                            setUf={setUf}
                            loading={loadingCep}
                          />
                        </div>
                      )
                    }
                  ]
                }
              />
              <Row 
                inputs={
                  [
                    {
                      espacoOcupado: 1,
                      label: 'Número *',
                      input: (
                        <div className='input-container'>
                          <TextInput
                            value={number}
                            setValue={setNumber}
                            required={true}
                          />
                        </div>
                      )
                    },
                    {
                      espacoOcupado: 2,
                      label: 'Complemento',
                      input: (
                        <div className='input-container'>
                          <TextInput
                            value={complement}
                            setValue={setComplement}
                          />
                        </div>
                      )
                    }
                  ]
                }
              />
              {cepEnabled ? (
                <>
                  <Divider
                    number={'3'}
                    text={'Forma de Envio'}
                    divider={true}
                  />
                  <DeliveryInfo 
                    cepEnabled={cepEnabled}
                    deliveryData={{
                      cep: cep,
                      address: address,
                      number: number,
                      city: city,
                      uf: uf,
                      deliveryMode: deliveryMode,
                      deliveryValue: deliveryTotalValue,
                      deliveryAmount: deliveryAmount,
                      amount: amount,
                    }}
                  />
                  <TotalRow 
                    value={total}
                  />
                  <div className='submit-container'>
                    <Button onClick={handleClick} disabled={(loadingApi || !buttonAvaliable())}>
                      {loadingApi ? (
                        <Loader 
                          size={'20px'}
                          primaryColor={'white'}
                          secondaryColor={'4px solid transparent'}
                        />
                      ) : (
                        'Concluir'
                      )}
                    </Button>
                  </div>
                </>
              ) : (
                <></>
              )}

              


            </div>
          </div>
      </div>
    </div>
  );
}

export default App;
