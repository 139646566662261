import { React, useState} from 'react';
import StyledInput  from './../StyledInput/StyledInput';
import { validateUF } from './../../utils/Validation';

export default function UfInput ({ uf, setUf, loading }) {
    const [error, setError] = useState(false);
    const maxLength = 2;
    const minLength = 2;

    function validate(uf) {
        const validation = validateUF(uf);
        if (validation){
            setError(false);
        } else if (uf === '') {
            setError("Este campo é obrigatório");
        } else if (uf.length < minLength){
            setError("UF inválido");
        } else {
            setError("UF inválido");
        }
    }

    const handleBlur = (uf) => {
        validate(uf);
    };

    const handleChange = async (uf) => {
        setUf(uf.toUpperCase());
        setError(false);
    };
  
    return (
      <div>
        <StyledInput
            type='text'
            value={uf}
            onChange={handleChange}
            onBlur={handleBlur}
            errorMessage={!validateUF(uf) ? (error) : (<></>)}
            loading={loading}
            maxLength={maxLength}/>
      </div>
    );
  };