import styled, { css } from 'styled-components';

export const InputContainer = styled.div`
  //position: relative;
`;

export const Input = styled.input`
  display: flex;
  align-items: center;
  height: 32px;
  background: #fff;
  border: none;
  border-radius: 8px;
  border-left: 1px #ddd solid;
  border-bottom: 4px #ddd solid;

  &:focus {
    border-color: #0090A7;
    outline: none;
  }

  ${({ green }) =>
    green &&
    css`
      border-color: green;
    `}
`;

export const ErrorMessage = styled.div`
  position: absolute;
  top: 50%;
  right: 4%;
  transform: translateY(-50%);
  color: red;
  font-size: 12px;
`;

export const LoadingIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 4%;
  transform: translateY(-50%);
  color: #0090A7;
  font-size: 12px;
`;