import React from 'react';
import {
    ProductValue,
    Container,
    ProductContainer,
    ProductName,
    ProductDiscountedValue,
    PriceContainer
} from './Styles';

export default function ProductsRow ({ products }) {
  function swapCommaDot(number) {
    let str = number.toString();
    let swappedStr = str
      .replace(/\./g, '#TEMP#')
      .replace(/,/g, '.')
      .replace(/#TEMP#/g, ',');
    return swappedStr;
  }

    return (
      <Container>
      {products.map((product, index) => (
        <ProductContainer key={index}>
          <ProductName>{product.name}</ProductName>
          <PriceContainer>
            <ProductValue>R${swapCommaDot(product.value.toFixed(2))}</ProductValue>
            <ProductDiscountedValue>R${swapCommaDot(product.discountedValue.toFixed(2))}</ProductDiscountedValue>
          </PriceContainer>
        </ProductContainer>
      ))}
    </Container>
    );
  };