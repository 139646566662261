import styled from 'styled-components';

export const Container = styled.div`
  width: 85%;
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 8px;
  gap: 4px;
`;

export const Label = styled.span`
  font-size: 14px;
  color: #222;
`;

export const Value = styled.span`
  color: #555;
  font-weight: bold;
`;

export const Message = styled.p`
  margin-top: 16px;
  color: #0090A7;
  text-align: center;
  font-weight: bold;
`;

export const ErrorMessage = styled.p`
  margin-top: 16px;
  color: #0090A7;
  text-align: center;
  font-weight: bold;
`;

export const CityState = styled.span`
  @media (max-width: 600px) {
    display: block;
    margin-top: 4px;
  }
`;