import React from 'react';
import styled from 'styled-components';

export const DividerContainer = styled.div`
  width: 90%;
  display: block;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: bolder;
`;

export const Text = styled.span`
  font-size: 16px;
  color: #0090A7;
`;

export const DividerLine = styled.hr`
  width: 100%;
  height: 1px;
  background-color: #0090A7;
  border: none;
  margin: 10px 0;
`;
