import React from 'react';
import styled from 'styled-components';

// Container flexível para a linha do total
export const TotalContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 8px;
  border-top: 1px solid #ccc;
  margin-top: 16px;
`;

export const TotalText = styled.div`
  text-align: right;
`;

export const TotalLabel = styled.div`
  font-weight: bold;
  color: #0090A7;
`;

export const TotalValue = styled.div`
  font-weight: bold;
  margin-top: 4px;
  color: #363636;
`;