import React from 'react';
import styled from 'styled-components';

export const Container = styled.div`
    color: #363636;
    font-size: 15px;
    width: 90%;
    margin-bottom: 16px;
`;

export const ProductContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px;
  gap: 20px;
`;

export const ProductName = styled.span`
  font-weight: bold;

  &::before {
    content: '•';
    margin-right: 8px;
    color: #363636;
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const ProductValue = styled.span`
  color: #777;
  font-size: 15px;
  text-decoration: line-through;
`;

export const ProductDiscountedValue = styled.span`
  font-size: 18px;
  font-weight: bolder;
`;