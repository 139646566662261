import React from 'react';
import LogoImg from './../../assets/Logo.svg'
import {
    HeaderContainer,
    Logo,
    TextDiv,
    Text,
    Title
} from './Styles'

export default function Header (){
    return (
      <HeaderContainer>
        <Logo src={LogoImg} alt="Logo" />
        <TextDiv>
          <Title>
            Assinatura
          </Title>
          <Text>
            Realize a assinatura do seu produto e receba no conforto da sua casa todo mês
          </Text>
        </TextDiv>
      </HeaderContainer>
    );
  };