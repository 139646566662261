import { React, useState} from 'react';
import StyledInput  from './../StyledInput/StyledInput';
import { validatePhone } from './../../utils/Validation';

export default function PhoneInput ({ phone, setPhone }) {
    const [error, setError] = useState(false);
    const maxLength = 15;

    function validate(phone) {
        const validation = validatePhone(phone);
        if (validation){
            setError(false);
        } else if (phone === '') {
            setError("Este campo é obrigatório");
        } else {
            setError("Celular inválido");
        }
    }

    const handleBlur = (phone) => {
        validate(phone);
    };

    const handleChange = async (phone) => {
        const cleanedPhone = phone.replace(/\D/g, '');
        let maskedPhone;
        if (cleanedPhone.length <= 10){
            maskedPhone = cleanedPhone.replace(/^(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
        } else if (cleanedPhone.length === 11) {
            maskedPhone = cleanedPhone.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        }
        setPhone(maskedPhone);
        setError(false);
    };
  
    return (
      <div>
        <StyledInput type='text' value={phone} onChange={handleChange} onBlur={handleBlur} errorMessage={error} loading={false} maxLength={maxLength}/>
      </div>
    );
  };