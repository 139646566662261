import React from 'react';
import styled from 'styled-components';

export const StyledButton = styled.button`
    width: 120px;
    height: 40px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    background-color: ${props => props.color || '#0090A7'};
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${props => (props.disabled ? 0.5 : 1)};
    color: white;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: ${props => props.colorhover || '#02A95E'};
    }
    &:active {
        background-color: ${props => props.colorhover || '#02A95E'};
    }
`;