class APIResponse {
    constructor(data = null, isSuccess = true, message = '') {
      this.data = data;
      this.isSuccess = isSuccess;
      this.message = message;
    }
  
    static success(data) {
      return new APIResponse(data, true, '');
    }
  
    static error(message) {
      return new APIResponse(null, false, message);
    }
  }
  
  export default APIResponse;