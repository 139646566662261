import React from 'react';
import {
  Text,
  DividerLine,
  DividerContainer
} from './Styles'

export default function Divider ({ number, text, divider }) {
    return (
      <DividerContainer>
        {number ? (
          <Text>{number}. {text}</Text>
        ) : (
          <Text>{text}</Text>
        )}
        {divider && <DividerLine/>}
      </DividerContainer>
    );
  };